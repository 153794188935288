import React from "react";
import { useState, useEffect } from "react";
import io from 'socket.io-client';

const Voice = () => {

    const [record,setrecord] = useState(false)
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const newSocket = io(`http://${window.location.hostname}:3000`);
        setSocket(newSocket);
        console.log(newSocket)
        return () => newSocket.close()
      }, [setSocket]);

    const handlevoice = () => {
        setrecord(!record)
    }

    if(record){
        return <div className="voice">
        <button style={{"fontSize": "24px","color" : "red"}}><i className="fa fa-microphone" onClick={(event) => handlevoice(event)}></i></button>
    </div>
    }
    else{
    return <div className="voice">
        <button style={{"fontSize": "24px"}}><i className="fa fa-microphone" onClick={(event) => handlevoice(event)}></i></button>
    </div>
    }
}


export default Voice;



