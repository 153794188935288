import React, { useEffect, useState } from "react";
// import GuestLogin from "./guestlogin";
// import UserLogin from "./userlogin";
import Chat from "./Chat";
// import { ReactComponent as YourSvg } from "../images/chatbot.svg";
// import Voiceauthentication from "./voiceAuthentication";
import { useCookies } from "react-cookie";
// import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import UserIcon from "../images/user_icon";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import chatboxImage from "./../images/botImage.jpeg";
// import { useContext } from "react";
// import { AppContext } from "../globalContext";
// import Voice from "./Voice";
import { ReactComponent as Userface } from "../images/chatbot.svg";
import { ReactComponent as Userimage } from "../images/profile-default-svgrepo-com.svg";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const Welcome = () => {
  //   const { userlogin, chatpage, logout } = useContext(AppContext);
  const [cookies, setCookie] = useCookies("user");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showImg, setShowImg] = useState(false);
  const [clicked, setClicked] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //   useEffect(() => {}, [clicked, chatpage, logout, userlogin]);
  const toggleState = (event) => {
    setClicked(!clicked);
    setShowImg(false);
  };
  useEffect(() => {
    if (cookies?.jwt) {
      setIsLoggedIn(true);
    }
  }, []);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const handleSubmit = async (profile) => {
    var url =
      profile == "guest"
        ? "https://bot.assisto.tech/guest?client_id=50&ip=49.37.222.67"
        : "https://bot.assisto.tech/verify?client_id=50&ip=157.45.214.206";
    var body =
      profile == "guest"
        ? {
            name: name,
            email: email,
          }
        : { email: email, password: password };
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data1 = await response.json();
      if (data1.auth_token) {
        setCookie("jwt", data1.auth_token);
        setCookie("message", data1.Message);
        setIsLoggedIn(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (isLoggedIn) {
    return <Chat />;
  } else {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "end",
            flexDirection: "column",
            width: "100vw",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
              margin: "10px 10px 10px 0",
            }}
          >
            <img
              src={require("../images/assisto_logo_blue.png")}
              style={{ height: "100px", marginRight: "10px" }}
              alt="Assisto"
            />
            <img
              style={{ height: "100px" }}
              src={require("../images/canara_bank_logo.png")}
              alt="Assisto"
            />
          </div>
          <p style={{ marginRight: "40px" }}>Demo Date: 24th March 2023</p>
        </div>
        <div className="container">
          <div
            className={
              clicked
                ? "chatbox__button chatbox__button--active"
                : "chatbox__button chatbox__button--inactive"
            }
            onClick={(event) => toggleState(event)}
          >
            {clicked ? (
              <CloseIcon fontSize="large" sx={{ color: "#811CC8" }} />
            ) : (
              <Userface />
              // <img src={require("./../images/user.png")} alt="" className="" />
            )}
          </div>
          <div
            className="chatbox"
            style={{
              opacity: clicked ? "1" : "0",
              transform: clicked ? "translateY(-1.5rem)" : "translateY(0rem)",
            }}
          >
            {clicked && (
              <img
                src={chatboxImage}
                onLoad={(el) => {
                  console.log("load finished", el);
                }}
                alt="ChatBox"
                className="chatbox_img_container"
                style={{
                  // visibility: showImg ? "visible" : "hidden",
                  opacity: showImg ? "1" : "0",
                }}
              />
            )}
            <div className="div">
              {clicked && (
                <IconButton
                  aria-label="arrow-button"
                  style={{
                    transform: showImg ? "rotate(180deg)" : "",
                    transition: "all 0.5s",
                  }}
                  color={showImg ? "secondary" : "primary"}
                  onClick={() => {
                    setShowImg((el) => !el);
                  }}
                >
                  <ArrowBackIosRoundedIcon />
                </IconButton>
              )}
            </div>
            {clicked && (
              <div className={clicked ? "chatbox--active" : "chatbox__support"}>
                <div className="chatbox__header">
                  <div className="chatbox__image--header">
                    <img
                      src={require("./../images/Assisto_Logo.png")}
                      alt="image"
                    />
                  </div>
                  {/* <div className="chatbox__content--header">
                <h4 className="chatbox__heading--header">iAssist</h4>
              </div> */}
                </div>
                <div
                  className="chatbox__messages"
                  style={{
                    height: "24rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab label="Guest" {...a11yProps(0)} />
                      <Tab label="User" {...a11yProps(1)} />
                      {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                    </Tabs>
                    <TabPanel value={value} index={0}>
                      <form
                        action=""
                        className=""
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit("guest");
                        }}
                      >
                        <TextField
                          id="filled-basic"
                          label="Email"
                          variant="filled"
                          sx={{ marginBottom: "1rem" }}
                          fullWidth
                          type="email"
                          required
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                        <TextField
                          id="filled-basic"
                          label="Name"
                          variant="filled"
                          sx={{ marginBottom: "1rem" }}
                          fullWidth
                          required
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                        <Button variant="contained" type="submit">
                          Submit
                        </Button>
                      </form>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <form
                        action=""
                        className=""
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmit("user");
                        }}
                      >
                        <TextField
                          id="filled-basic"
                          label="Email"
                          variant="filled"
                          sx={{ marginBottom: "1rem" }}
                          fullWidth
                          type="email"
                          required
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                        <TextField
                          id="filled-basic"
                          label="Password"
                          variant="filled"
                          type="password"
                          sx={{ marginBottom: "1rem" }}
                          fullWidth
                          required
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                        <Button variant="contained" type="submit">
                          Submit
                        </Button>
                      </form>
                    </TabPanel>
                    {/* <TabPanel value={value} index={2}>
                      Item Three
                    </TabPanel> */}
                  </Box>
                  {/* */}
                </div>
                <div className="chatbox__footer">
                  <input
                    type="text"
                    placeholder="Write a message..."
                    disabled
                  />
                  <form
                    action=""
                    className=""
                    onSubmit={(e) => {
                      e.preventDefault();
                      //   handleBotCall();
                    }}
                  >
                    <IconButton
                      aria-label="arrow-button"
                      type="Submit"
                      variant="contained"
                      disabled
                      sx={{}}
                    >
                      <SendRoundedIcon sx={{ color: "white" }} />
                    </IconButton>
                  </form>

                  <IconButton aria-label="arrow-button">
                    <KeyboardVoiceIcon sx={{ color: "white" }} />
                  </IconButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
};

export default Welcome;
