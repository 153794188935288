import "./App.scss";
import Welcome from "./components/welcome";
import "./main.css";
import Chat from "./components/Chat";
var vad = require("./components/index");
function App() {
  // var audioContext;

  // var valueContainer = document.createElement("div");
  // document.body.appendChild(valueContainer);

  // var stateContainer = document.createElement("div");
  // document.body.appendChild(stateContainer);

  // requestMic();

  // function requestMic() {
  //   try {
  //     window.AudioContext = window.AudioContext || window.webkitAudioContext;
  //     audioContext = new AudioContext();

  //     navigator.getUserMedia =
  //       navigator.getUserMedia ||
  //       navigator.webkitGetUserMedia ||
  //       navigator.mozGetUserMedia ||
  //       navigator.msGetUserMedia;
  //     navigator.getUserMedia(
  //       { audio: true },
  //       startUserMedia,
  //       handleMicConnectError
  //     );
  //   } catch (e) {
  //     handleUserMediaError();
  //   }
  // }

  // function handleUserMediaError() {
  //   console.warn("Mic input is not supported by the browser.");
  // }

  // function handleMicConnectError() {
  //   console.warn(
  //     "Could not connect microphone. Possible rejected by the user or is blocked by the browser."
  //   );
  // }

  // function startUserMedia(stream) {
  //   var options = {
  //     onVoiceStart: function () {
  //       console.log("voice start");
  //       stateContainer.innerHTML = "Voice state: <strong>active</strong>";
  //     },
  //     onVoiceStop: function () {
  //       console.log("voice stop");
  //       stateContainer.innerHTML = "Voice state: <strong>inactive</strong>";
  //       console.log(
  //         "audio context",
  //         audioContext,
  //         "strea",
  //         stream.getAudioTracks()
  //       );
  //     },
  //     onUpdate: function (val) {
  //       //console.log('curr val:', val);
  //       valueContainer.innerHTML =
  //         "Current voice activity value: <strong>" + val + "</strong>";
  //     },
  //   };
  //   vad(audioContext, stream, options);
  //   // console.log("vaad", vad);
  // }
  return <Welcome />;
}

export default App;
