import React, { useContext } from "react"
import { useState } from "react"

export const AppContext =  React.createContext({})


export const AppProvider = ({ children }) => {
    const [userlogin, setUserLogin] = useState("")
    const [chatpage, setChatPage] = useState(false)
    const [logout, setLogout] = useState(true)
    const [isLogin,setIsLogin] = useState(false)

    const [on, setOn] = useState(false)
    const Toggle = (event) => {
        setUserLogin(event.target.innerHTML)
    }

    return<AppContext.Provider value={{ Toggle, userlogin, chatpage, setChatPage ,logout, setLogout, isLogin, setIsLogin, on, setOn}}>
                {children}
            </AppContext.Provider>
 
}



