import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import UserIcon from "../images/user_icon";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import chatboxImage from "./../images/botImage.jpeg";
import { useContext } from "react";
import { AppContext } from "../globalContext";
import Voice from "./Voice";
import { ReactComponent as Userface } from "../images/chatbot.svg";
import { ReactComponent as Userimage } from "../images/profile-default-svgrepo-com.svg";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import SwitchNER from "./NER_switch";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import CloseIcon from "@mui/icons-material/Close";
import taj from "./../images/taj.jpg";
import Card from "@mui/material/Card";
// import {
//   Button,
//   FormControl,
//   MenuItem,
// } from "@material-ui/core";

import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useCookies } from "react-cookie";
// let duration;
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Feedback } from "@mui/icons-material";
const Chat = () => {
  const { setLogout, setIsLogin, on } = useContext(AppContext);
  const [text, setText] = useState("");
  const [userList, setUserList] = useState([]);
  const [clicked, setClicked] = useState(true);
  const [ner, setNer] = useState(false);
  const [nerval, setNerVal] = useState([]);
  const [nerent, setNerEnt] = useState([]);
  const [nerSend, setnerSend] = useState([]);
  const [nerAnn, setnerAnn] = useState(false);
  const [nerannent, setnerannent] = useState([]);
  const [nerannval, setnerannval] = useState([]);
  const [sendNerAnn, setSendNerAnn] = useState({});
  const [micActive, setMicActive] = useState(false);
  const [carousel, setCarousel] = useState(0);
  const inputRef = useRef(null);
  const [transcript, setTranscript] = useState("");
  const [state, setState] = useState();
  const [duration, setDuration] = useState();
  const [dropDown, setDropDown] = useState([]);
  const [cookies] = useCookies("name");
  const [checkBox, setCheckBox] = useState([]);

  const [selectedOption,setSelectedOption] = useState([])
  // console.log(cookies);
  const toggleState = (event) => {
    setClicked(!clicked);
    setShowImg(false);
  };
  const createAudio = async (text) => {
    console.log("text11", text);
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 15000);
    try {
      const response1 = await fetch("http://dev.assisto.tech/get_avatar", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          text: text,
        }),
        signal: controller.signal,
      });
      console.log(response1);
      const data1 = await response1.json();

      const audio = new Audio();
      audio.src = "data:audio/wav;base64," + data1.audio;

      audio.addEventListener("loadedmetadata", () => {
        // Get the duration of the audio file
        const duration = audio.duration;
        setDuration(duration);
      });
      // audio.addEventListener("play", () => {
      //   console.log("playing");
      //   setDuration(duration);
      // });
      if (data1.gif) {
        // audio.play();
        return { gif: data1.gif };
      }
    } catch (err) {
      return { error: "No response" };
    }
    // console.log("data", data);
    // snd.play();
  };
  const [showImg, setShowImg] = useState(false);
  useEffect(() => {
    const recognition = new window.webkitSpeechRecognition();

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setTranscript(transcript);
      setMicActive(false);
      recognition.stop();
    };
    if (micActive) recognition.start();
    else recognition.stop();
    return () => {
      recognition.stop();
    };
  }, [micActive]);
  useEffect(() => {
    if (transcript) handleBotCall(transcript);
  }, [transcript]);

  useEffect(() => {
    // console.log(state, duration);
    console.log(state);
    if (state) {
      setTimeout(() => {
        setState("");
        setDuration(0);
      }, duration * 1000);
    }
  }, [duration]);
  const botAsk = async (client, text1, feedback) => {
    setText("");
    // console.log(text1);
    const auth = localStorage.getItem("token");
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 8000);
    const BotUrl = `https://bot.assisto.tech/bot?client_id=50&question=${text1}`;
    try {
      const response = await fetch(BotUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: cookies.jwt,
        },
        signal: controller.signal,
      });
      // console.log(await response.json());
      const data = await response.json();
      console.log("data", data);
      const outtext = data.output[0];

      // then do it

      // const res = "bot replying"
      if (data.data_type === "check_box") {
        setNer(true);
        setNerVal(data.data.value);
        setNerEnt(data.data.entity);

        await createAudio(outtext);

        setUserList([
          ...userList,
          { agent: "user", message: feedback || text1 },
          { agent: "bot", message: outtext },
        ]);
      } else if (data.data_type == "string" && Array.isArray(data.data)) {
        const data_audio = await createAudio(data.output[0] + data.data);
        if (data_audio.error) {
          setState("");
        } else {
          setState(data_audio.gif);
        }

        setUserList([
          ...userList,
          { agent: "user", message: feedback || text1 },
          {
            agent: "bot",
            message: data.data,
            type: "button",
            title: data.output[0],
          },
        ]);
      } else if (data.data_type == null || data.data_type == "string") {
        const data_audio = await createAudio(data.output[0]);
        if (data_audio.error) {
          setState("");
        } else {
          setState(data_audio.gif);
        }
        setUserList([
          ...userList,
          { agent: "user", message: feedback || text1 },
          {
            agent: "bot",
            message: data.output[0],
          },
        ]);
      } else if (data.data_type === "annotation_box") {
        // console.log(send);
        //setNerEnt(data.data.entity)
        //setNerVal(data.data.value)

        const data_audio = await createAudio(outtext);
        if (data_audio.error) {
          setState("");
        } else {
          setState(data_audio.gif);
        }

        // console.log("message::", data.data.entity);
        setUserList([
          ...userList,
          { agent: "user", message: feedback || text1 },
          {
            agent: "bot",
            message: data.data.entity,
            value: data.data.value,
            type: "annotation_box",
            title: data.output[0],
          },
        ]);
      }
      //{"output": ["Please select the type of connection you want"], "data_type": "Checkbox", "data": ["Household", "Commercial"], "image": ""}
      else if (data.data_type == "Checkbox") {
        const data_audio = await createAudio(data.output[0]);
        if (data_audio.error) {
          setState("");
        } else {
          setState(data_audio.gif);
        }
        setUserList([
          ...userList,
          { agent: "user", message: feedback || text1 },
          {
            agent: "bot",
            message: data.data,
            type: "checkbox",
            title: data.output[0],
          },
        ]);
      } else if (data.data) {
        const options = [];
        for (var i = 0; i < data.data.length; i++) {
          options.push({ agent: "bot-option", message: data.data[i] });
        }
        text.startsWith("[") ? (text = "feedback") : (text = text);

        const data_audio = await createAudio(outtext);
        if (data_audio.error) {
          setState("");
        } else {
          setState(data_audio.gif);
        }
        // setDuration(data_audio.duration);
        setUserList([
          ...userList,
          { agent: "user", message: feedback || text1 },
          { agent: "bot", message: outtext },
          ...options,
        ]);
      } else {
        text.startsWith("[") ? (text = "feedback") : (text = text);

        const data_audio = await createAudio(outtext);
        if (data_audio.error) {
          setState("");
        } else {
          setState(data_audio.gif);
        }
        // setDuration(data_audio.duration);

        setUserList([
          ...userList,
          { agent: "user", message: feedback || text1 },
          { agent: "bot", message: outtext },
        ]);
      }
    } catch (error) {
      // console.log(error);
      setUserList([
        ...userList,
        { agent: "user", message: feedback || text1 },
        {
          agent: "bot",
          message: "Failed to respond",
        },
      ]);
    }
  };

  const handleBotCall = (text1) => {
    setUserList([
      ...userList,
      { agent: "user", message: text || text1 },
      { agent: "typing", message: "..." },
    ]);
    // setapicall(true)
    // console.log("helo", text || text1);
    botAsk(50, text || text1);
  };

  const formatNER = (data) => {
    let send_data = [];
    for (let i = 0; i < data.length; i++) {
      const ent = data[i].entity;
      const val = data[i].value;
      send_data.push({ [ent]: val });
    }
    return send_data;
  };

  const handleNER = (event) => {
    const data = formatNER(nerSend);
    const st = JSON.stringify(data);
    botAsk(1, st);
    setUserList([
      ...userList,
      { agent: "user", message: "feedback" },
      { agent: "typing", message: "..." },
    ]);
    setNer(false);
    setnerSend([]);
    setNerEnt([]);
    setNerVal([]);
  };

  const handleNERANN = (event) => {
    let result = [];
    console.log(sendNerAnn);
    Object.keys(sendNerAnn).map((item) => {
      result.push({ [item]: sendNerAnn[item] });
    });
    const st = JSON.stringify(result);
    console.log(st);
    botAsk(1, st);
    setUserList([
      ...userList,
      { agent: "user", message: "feedback" },
      { agent: "typing", message: "..." },
    ]);
    setnerAnn(false);
    setnerannent([]);
    setnerannval([]);
  };

  const handleLogout = (e) => {
    localStorage.clear();
    setLogout(false);
    setUserList([]);
    localStorage.clear();
    setIsLogin(false);
  };

  const handleOption = (event) => {
    setUserList([
      ...userList,
      { agent: "user", message: event.target.innerHTML },
      { agent: "typing", message: "..." },
    ]);
    botAsk(40, event.target.innerHTML);
  };

  const handleChecked = (event) => {
    const t_id = event.target.id;
    const ner_e = nerent[t_id];
    const ner_v = nerval[t_id];
    const dat = { entity: ner_e, value: ner_v };

    if (event.target.checked) {
      // setnerSend([...nerSend,{])
      nerSend.push(dat);
      console.log(nerSend);
      setnerSend(nerSend);
    } else {
      nerSend.pop(dat);
      setnerSend([...nerSend]);
    }
  };
  // console.log(userList);
  const handleNERANNchange = (event) => {
    const entity = event.target.id;
    const value = event.target.value;
    sendNerAnn[entity] = value;
    console.log(sendNerAnn);
  };
  // console.log("state", state);
  useEffect(() => {
    const name = localStorage.getItem("Message");
    localStorage.setItem("conversation", JSON.stringify(userList));
    const conv = JSON.parse(localStorage.getItem("conversation"));
    // console.log(conv.length !== userList.length)
    // if(userList.length !== conv.length){
    //     setUserList(conv)
    // }
    if (!userList && localStorage.getItem("conversation")) {
      console.log("here");
      setUserList(conv);
    } else if (userList.length === 0) {
      setUserList([
        ...userList,
        { agent: "bot", message: cookies.message },
        { agent: "bot", message: "How can i help you ?" },
      ]);
    }
    // botAsk(1,text)
  }, [userList, text, ner, nerAnn, nerAnn, on]);
  useEffect(() => {
    // console.log(carousel);
    if (inputRef && inputRef.current) {
      const max = [...inputRef.current.children].length - 1;
      // if (carousel > (max - 1) * 100 || carousel < -max * 100) {
      //   setCarousel(0);
      // }
      // inputRef.current.style.transform = `translateX(${carousel}%)`;
      [...inputRef.current.children].forEach((el) => {
        el.style.transition = `all 1s`;
        el.style.transform = `translateX(${carousel}%)`;
      });
      // console.log([...inputRef.current.children]);
    }
  }, [carousel]);
  const chat_ref = useRef(null);

  useEffect(() => {
    if (chat_ref.current) {
      chat_ref.current.children[
        chat_ref.current.children.length - 1
      ].scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  });
  const carouselTranslate = (number) => {
    setCarousel((el) => {
      if (number > 0 && el == 0) {
        return 0;
      }
      if (inputRef && inputRef.current) {
        const max = [...inputRef.current.children].length - 1;
        if (el > (max - 1) * 100 || el < -(max - 1) * 100) {
          return 0;
        }
        return Number(el) + Number(number);
      }
    });
  };
  return (
    <div className="container">
      {/* <div className="logout">
        <button onClick={(e) => handleLogout(e)}>Logout</button>
      </div>
      <SwitchNER /> */}
      <div
        className={
          clicked
            ? "chatbox__button chatbox__button--active"
            : "chatbox__button chatbox__button--inactive"
        }
        onClick={(event) => toggleState(event)}
      >
        {clicked ? (
          <CloseIcon fontSize="large" sx={{ color: "#811CC8" }} />
        ) : (
          <Userface />
          // <img src={require("./../images/user.png")} alt="" className="" />
        )}
      </div>
      <div
        className="chatbox"
        style={{
          opacity: clicked ? "1" : "0",
          transform: clicked ? "translateY(-1.5rem)" : "translateY(0rem)",
        }}
      >
        {clicked && !state && (
          <img
            src={state ? "data:image/jpeg;base64," + state : chatboxImage}
            onLoad={(el) => {
              console.log("load finished", el);
            }}
            alt="ChatBox"
            className="chatbox_img_container"
            style={{
              // visibility: showImg ? "visible" : "hidden",
              opacity: showImg ? "1" : "0",
            }}
          />
        )}
        {clicked && state && (
          <video autoPlay>
            <source
              src={"data:video/mp4;base64," + state}
              type="video/mp4"
              className="chatbox_img_container"
            />
            Your browser does not support the video tag.
          </video>
        )}
        <div className="div">
          {clicked && (
            <IconButton
              aria-label="arrow-button"
              style={{
                transform: showImg ? "rotate(180deg)" : "",
                transition: "all 0.5s",
              }}
              color={showImg ? "secondary" : "primary"}
              onClick={() => {
                setShowImg((el) => !el);
              }}
            >
              <ArrowBackIosRoundedIcon />
            </IconButton>
          )}
        </div>
        {clicked && (
          <div className={clicked ? "chatbox--active" : "chatbox__support"}>
            <div className="chatbox__header">
              <div className="chatbox__image--header">
                <img
                  src={require("./../images/Assisto_Logo.png")}
                  alt="image"
                />
              </div>
              {/* <div className="chatbox__content--header">
                <h4 className="chatbox__heading--header">iAssist</h4>
              </div> */}
            </div>
            <div className="chatbox__messages" style={{ height: "24rem" }}>
              <div ref={chat_ref}>
                {userList.map((ojb, index) => {
                  if (ojb.agent === "bot-option") {
                    return (
                      <div
                        key={index}
                        className="messages__item messages__item--visitor"
                      >
                        <button onClick={(event) => handleOption(event)}>
                          {ojb.message}
                        </button>
                      </div>
                    );
                  } else if (ojb.agent === "bot" || ojb.agent === "user") {
                    if (ojb.agent == "bot" && ojb.type == "button") {
                      return (
                        <div key={index} className="message__container ">
                          <div className="Userimage">
                            {/* <UserIcon /> */}
                            {/* <Userface /> */}
                            <img
                              src={require("./../images/user.png")}
                              alt=""
                              className=""
                            />
                          </div>

                          <div className={"message__item__button__list"}>
                            <h3 style={{ marginBottom: "8px" }}>{ojb.title}</h3>
                            {ojb.message.map((el) => {
                              return (
                                <button
                                  className="message__item--button"
                                  onClick={(e) => {
                                    // console.log(e.target.innerHTML);
                                    // setText(e.target.innerHTML);
                                    handleBotCall(e.target.innerHTML);
                                  }}
                                >
                                  {el}
                                </button>
                              );
                            })}
                          </div>
                        </div>
                      );
                    } else if (
                      ojb.agent == "bot" &&
                      ojb.type == "annotation_box"
                    ) {
                      return (
                        <>
                          <div key={index} className="message__container ">
                            <div className="Userimage">
                              {/* <Userface /> */}
                              <img
                                src={require("./../images/user.png")}
                                alt=""
                                className=""
                              />
                              {/* <UserIcon /> */}
                            </div>

                            <div className={"message__item__button__list"}>
                              <h3 style={{ marginBottom: "8px" }}>
                                {ojb.title}
                              </h3>
                              <form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  console.log(dropDown);
                                  const st = JSON.stringify(dropDown);
                                  botAsk(50, st, "FeedBack Accepted");
                                  setText("");
                                  setUserList([
                                    ...userList,
                                    { agent: "user", message: "feedback" },
                                    { agent: "typing", message: "..." },
                                  ]);

                                  setDropDown([]);
                                }}
                              >
                                {ojb.value.map((element) => {
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "flex-start",
                                      }}
                                    >
                                      <p>{element}</p>
                                      <FormControl
                                        sx={{ m: 1, minWidth: 120 }}
                                        size="large"
                                        // fullWidth
                                      >
                                        {/* {ojb.value.map((element)=>{
                                  
                                })} */}
                                        <InputLabel id="demo-select-small">
                                          Select
                                        </InputLabel>
                                        <Select
                                          labelId="demo-select-small"
                                          id="demo-select-small"
                                          // value={dropDown}
                                          label="Select"
                                          onChange={(e) => {
                                            // console.log(
                                            //   dropDown,
                                            //   e.target.value
                                            // );
                                            // setDropDown(e.target.value);
                                            setDropDown((el) => {
                                              let obj = {};
                                              obj[`${element}`] =
                                                e.target.value;
                                              // const result = ;
                                              console.log(obj, el);
                                              el.push(obj);
                                              return el;
                                            });

                                            // handleBotCall(e.target.value);
                                            console.log(e);
                                            e.target.innerText = e.target.value;
                                          }}
                                        >
                                          {ojb.message.map((el) => (
                                            <MenuItem value={el}>{el}</MenuItem>
                                          ))}

                                          {/* <MenuItem value={10}>Ten</MenuItem>
                                  <MenuItem value={20}>Twenty</MenuItem>
                                  <MenuItem value={30}>Thirty</MenuItem> */}
                                        </Select>
                                      </FormControl>
                                    </div>
                                  );
                                })}
                                <Button variant="contained" type="submit">
                                  Submit
                                </Button>
                              </form>
                            </div>
                          </div>
                        </>
                      );
                    } else if (ojb.agent == "bot" && ojb.type == "checkbox") {
                      return (
                        <>
                          <div key={index} className="message__container ">
                            <div className="Userimage">
                              {/* <Userface /> */}
                              <img
                                src={require("./../images/user.png")}
                                alt=""
                                className=""
                              />
                              {/* <UserIcon /> */}
                            </div>

                            <div className={"message__item__button__list"}>
                              <h3 style={{ marginBottom: "8px" }}>
                                {ojb.title}
                              </h3>
                              <form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  // let string = checkBox.
                                  console.log(checkBox)
                                  handleBotCall(checkBox[0]);
                                  setCheckBox([]);
                                }}
                              >
                                {ojb.message.map((el) => {
                                  return (
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      label={el}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setCheckBox((elem) => {
                                            elem.push(el);
                                            console.log(el)
                                            return elem;
                                          });
                                        } else {
                                          setCheckBox((elem) => {
                                            let filter_element = elem.filter(
                                              (fil) => {
                                                console.log(e.target.value)
                                                return fil != el;
                                              }
                                            );
                                            return filter_element;
                                          });
                                        }
                                      }}
                                    />
                                  );

                                })}
                                <Button variant="contained" type="submit">
                                  Submit
                                </Button>
                              </form>
                            </div>
                          </div>
                        </>
                      );
                    }  else {
                      return (
                        <div key={index} className="message__container">
                          {ojb.agent === "bot" && (
                            <div className="Userimage">
                              {/* <Userface /> */}
                              <img
                                src={require("./../images/user.png")}
                                alt=""
                                className=""
                              />
                              {/* <UserIcon /> */}
                            </div>
                          )}
                          <div
                            className={
                              ojb.agent === "bot"
                                ? "messages__item messages__item--visitor"
                                : "messages__item messages__item--operator"
                            }
                          >
                            {ojb.message}
                          </div>
                          {ojb.agent === "user" && (
                            <div className="Userimage">
                              {/* <Userimage /> */}
                              <UserIcon />
                            </div>
                          )}
                        </div>
                      );
                    }
                  }
                  // if (text == "button") {
                  //   return (
                  //     <div key={index} className="message__container ">
                  //       <div className="Userimage">
                  //         <Userface />
                  //       </div>
                  //       <div className={"message__item__button__list"}>
                  //         {ojb.message.map((el) => {
                  //           return (
                  //             <button
                  //               className="message__item--button"
                  //               onClick={(e) => {
                  //                 console.log(e);
                  //               }}
                  //             >
                  //               {el}
                  //             </button>
                  //           );
                  //         })}
                  //       </div>
                  //     </div>
                  //   );
                  // }
                  if (text == "carousel") {
                    return (
                      <div key={index} className="message__container">
                        <IconButton
                          aria-label="arrow-button"
                          style={{
                            alignSelf: "center",
                            borderRadius: ".5",
                            border: "1px solid #811CC8",
                          }}
                          color={showImg ? "secondary" : "primary"}
                          onClick={() => {
                            carouselTranslate(-100);
                          }}
                        >
                          <ArrowBackIosRoundedIcon
                            fontSize="small"
                            sx={{ color: "#811CC8" }}
                          />
                        </IconButton>
                        <div
                          className={"message__item__carousel__list"}
                          ref={inputRef}
                        >
                          <Card sx={{ minWidth: "1" }}>
                            <CardMedia
                              component="img"
                              alt="green iguana"
                              height="140"
                              image={taj}
                            />
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                              >
                                Lizard
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                Lizards are a widespread group of squamate
                                reptiles, with over 6,000 species, ranging
                                across all continents except Antarctica
                              </Typography>
                            </CardContent>
                            <CardActions>
                              <Button size="small">Share</Button>
                              <Button size="small">Learn More</Button>
                            </CardActions>
                          </Card>
                          <Card sx={{ minWidth: "1" }}>
                            <CardMedia
                              component="img"
                              alt="green iguana"
                              height="140"
                              image={taj}
                            />
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                              >
                                Lizard
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                Lizards are a widespread group of squamate
                                reptiles, with over 6,000 species, ranging
                                across all continents except Antarctica
                              </Typography>
                            </CardContent>
                            <CardActions>
                              <Button size="small">Share</Button>
                              <Button size="small">Learn More</Button>
                            </CardActions>
                          </Card>
                          <Card sx={{ minWidth: "1" }}>
                            <CardMedia
                              component="img"
                              alt="green iguana"
                              height="140"
                              image={taj}
                            />
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="h5"
                                component="div"
                              >
                                Lizard
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                Lizards are a widespread group of squamate
                                reptiles, with over 6,000 species, ranging
                                across all continents except Antarctica
                              </Typography>
                            </CardContent>
                            <CardActions>
                              <Button size="small">Share</Button>
                              <Button size="small">Learn More</Button>
                            </CardActions>
                          </Card>
                        </div>
                        <IconButton
                          aria-label="arrow-button"
                          style={{
                            alignSelf: "center",
                            borderRadius: ".5",
                            border: "1px solid #811CC8",
                          }}
                          color={showImg ? "secondary" : "primary"}
                          onClick={() => {
                            carouselTranslate(100);
                          }}
                        >
                          <ArrowForwardIosIcon
                            fontSize="small"
                            sx={{ color: "#811CC8" }}
                          />
                        </IconButton>
                      </div>
                    );
                  } else {
                    return (
                      <div key={index} className="typing_box">
                        <div className="messages__item--typing"></div>
                        <div className="messages__item--typing"></div>
                        <div className="messages__item--typing"></div>
                      </div>
                    );
                  }
                })}

                <div className="ner">
                  {!ner ? (
                    <div className="checkbox_hidden"></div>
                  ) : (
                    nerent.map((ent, index) => {
                      return (
                        <div key={index} className="checkbox_ner">
                          <h4>{ent}</h4>
                          <label>
                            <input
                              type="checkbox"
                              id={index}
                              onChange={(event) => handleChecked(event)}
                            />
                            {nerval[index]}
                          </label>
                        </div>
                      );
                    })
                  )}
                  <div className="ner_submit">
                    {!ner ? (
                      <div className="ner_submit_off"></div>
                    ) : (
                      <button
                        onClick={(event) => {
                          handleNER(event);
                        }}
                      >
                        submit
                      </button>
                    )}
                  </div>
                </div>
                <div className="ner_annot">
                  {!nerAnn ? (
                    <div className="ner_annot_hidden"></div>
                  ) : (
                    nerannval.map((ent, index) => {
                      return (
                        <div className="ner_annot_block" key={index}>
                          <form>
                            <label>{ent}</label>
                            <select
                              id={ent}
                              onChange={(event) => handleNERANNchange(event)}
                            >
                              {nerannent.map((val, indext) => {
                                if (val == "None") {
                                  return (
                                    <option key={indext} selected>
                                      {val}
                                    </option>
                                  );
                                } else {
                                  return <option key={indext}>{val}</option>;
                                }
                              })}
                            </select>
                          </form>
                        </div>
                      );
                    })
                  )}

                  <div className="nerann_submit">
                    {!nerAnn ? (
                      <div className="nerann_submit_off"></div>
                    ) : (
                      <button
                        onClick={(event) => {
                          handleNERANN(event);
                        }}
                      >
                        submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="chatbox__footer">
              <input
                type="text"
                placeholder="Write a message..."
                value={text}
                onChange={(event) => {
                  setText(event.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key == "Enter") {
                    // console.log(e);
                    handleBotCall();
                  }
                }}
              />
              <form
                action=""
                className=""
                onSubmit={(e) => {
                  e.preventDefault();
                  handleBotCall();
                }}
              >
                <IconButton
                  aria-label="arrow-button"
                  // color={micActive ? "error" : "success"}
                  disabled={text ? false : true}
                  // color={text ? "primary" : "alert"}
                  type="Submit"
                  variant="contained"
                  sx={{}}
                  // onClick={() => {
                  //   setMicActive((el) => !el);
                  // }}
                >
                  <SendRoundedIcon sx={{ color: "white" }} />
                </IconButton>
                {/* <button
                  className="chatbox__send--footer send__button"
                  disabled={text ? false : true}
                  type="Submit"
                >
                  Send
                </button> */}
              </form>

              <IconButton
                aria-label="arrow-button"
                color={micActive ? "error" : "success"}
                onClick={() => {
                  setMicActive((el) => !el);
                }}
              >
                <KeyboardVoiceIcon
                  sx={{ color: micActive ? "red" : "white" }}
                />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;
